import React, { useEffect } from 'react';

const NotFound = () => {
  useEffect(() => {

    const metaTag = document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = 'noindex, nofollow';
    document.head.appendChild(metaTag);

    document.body.setAttribute('data-status', '404');
  }, []);

  return (
    <div className='flex flex-col items-center justify-center min-h-screen'>
      <h1 className='text-4xl font-bold'>404</h1>
      <p className='text-lg'>Page not found</p>
    </div>
  );
};

export default NotFound;
