import { sendUserInfoRequest } from '~/api/user/UserInfoRequestPacket'
import { getWebsiteNameCapitalized } from '~/lib/utils'
import { Footer } from '~/shared/ui/footer'
import { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import DiscordLoginButton from './auth/DiscordLoginButton'
import { EmailLoginButton } from './auth/EmailLoginButton'
import { GoogleLoginButton } from './auth/GoogleLoginButton'
import Faq from './faq'
import Loader from './loader'

export const General = () => {
	const { isDemo } = useSelector((state: any) => state.autoMaskReducer)

	const navigate = useNavigate()
	const { t } = useTranslation()

	const params = new URLSearchParams(window.location.search)
	const utmParameters = [
		'utm_source',
		'utm_medium',
		'utm_campaign',
		'utm_content',
		'utm_term',
	]

	for (let param of utmParameters) {
		let value = params.get(param)
		if (value) {
			Cookies.set(param, value)
		}
	}

	const [authorized, setAuthorized] = useState(false)

	useEffect(() => {
		const checkAuthorization = async () => {
			if (Cookies.get('token') !== undefined) {
				await sendUserInfoRequest()
					.then(() => {
						setAuthorized(true)
					})
					.catch(() => {
						setAuthorized(false)
					})
			} else {
				setAuthorized(false)
			}
		}

		checkAuthorization()
	}, [])

	useEffect(() => {
		const subId = params.get('subid')

		if (subId) {
			Cookies.set('subId', subId, { expires: 60 })
			params.delete('subId')
			window.history.replaceState({}, '', window.location.pathname)
		}
	}, [])

	return (
		<HelmetProvider>
			<Helmet>
				<link rel='canonical' href='https://undress.love' />
			</Helmet>
			<div className='flex flex-col gap-[2rem] p-[16px] mmd:gap-[2rem] mmd:p-[24px]'>
				<div className='flex flex-col gap-[40px] mmd:flex-row mmd:gap-[30px]'>
					<div className='flex flex-col gap-[24px] mmd:w-full'>
						<div className='flex items-center gap-[0.5rem] self-stretch text-[1.5rem] mmd:hidden'>
							<div className='flex h-[4rem] flex-col items-center justify-center gap-[0.25rem] rounded-[0.75rem] p-0'>
								<img className='h-[4rem]' src='logo_rounded.svg' />
							</div>
							{getWebsiteNameCapitalized()}
						</div>
						<div className='general-bg relative flex h-[calc(100vh-550px)] shrink-0 items-end justify-center self-stretch rounded-[20px] bg-[#161616] mmd:h-[calc(100vh-60px)]'>
							<Loader />
							<div className='general-banner-scan'>
								<div className='general-banner absolute bottom-0 flex h-[calc(100vh-550px)] w-full rounded-[20px] mmd:h-[calc(100vh-60px)]' />
							</div>
						</div>
						<div className='flex flex-col gap-[32px] mmd:hidden'>
							{authorized || isDemo ? (
								<button
									className='flex justify-center rounded-full bg-gradient-to-br from-[#ff8c00] via-[#ff7801] to-[#ff3915] px-2 py-5 text-[24px] font-medium transition-all active:scale-[97%]'
									onClick={() => {
										navigate('/edit')
									}}
								>
									{t('launch')}
								</button>
							) : (
								<>
									<div className='flex w-full flex-col items-center gap-[16px]'>
										<GoogleLoginButton />
										{/* <DiscordLoginButton /> */}
										<EmailLoginButton />
									</div>
								</>
							)}
							<div className='flex w-full flex-col gap-[16px] text-[20px] mmd:text-[22px]'>
								<h1 className='mb-2 text-[28px] font-bold sm:text-[18px] lg:text-[20px]'>
									Undress AI App: try Free AI Undress to make Nudes
								</h1>
								<p className='mb-2 text-[16px] leading-[28px] sm:text-[14px] sm:leading-[24px] lg:text-[12px]'>
									The Undress AI Tool is a revolutionary generator designed to
									create realistic deepnude images with ease. This Best Undress
									App lets you transform anyone on a photo into a deepfake
									result within seconds, offering seamless and accurate outputs.
								</p>
								<h2 className='mb-2 text-[28px] font-bold sm:text-[18px] lg:text-[20px]'>
									Undress AI Generator Result Examples
								</h2>
								<p className='mb-2 text-[16px] leading-[28px] sm:text-[14px] sm:leading-[24px] lg:text-[12px]'>
									The Undress App Results highlight the precision and realism of
									this innovative nudify tool. Browse the gallery to view
									stunning nudes examples, demonstrating why the Undress Nude
									App is trusted for creating accurate and impressive deepnude
									transformations quickly and effortlessly.
								</p>
								<h2 className='mb-2 text-[28px] font-bold sm:text-[18px] lg:text-[20px]'>
									Get Started with the Best AI Undress Tool Now!
								</h2>
								<p className='mb-2 text-[16px] leading-[28px] sm:text-[14px] sm:leading-[24px] lg:text-[12px]'>
									The Undress Love AI tool combines simplicity and efficiency,
									making it the perfect choice for realistic transformations.
									Whether you're seeking accuracy or speed, this Free Undress CC
									App ensures impressive nudity results in seconds.
								</p>
								<p className='text-[16px] leading-[28px] sm:text-[14px] sm:leading-[24px] lg:text-[12px]'>
									Join countless users who trust this innovative AI solution for
									quality nudes making, proving why it stands out as the
									ultimate choice in AI-powered undressing.
								</p>
							</div>
						</div>
					</div>
					<div className='hidden flex-col justify-between gap-[28px] mmd:flex'>
						<div className='flex flex-col gap-[15px]'>
							<div className='flex items-center gap-[0.5rem] self-stretch text-[1.5rem]'>
								<div className='flex h-[4rem] flex-col items-center justify-center gap-[0.25rem] rounded-[0.75rem] p-0'>
									<img className='h-[4rem]' src='logo_rounded.svg' />
								</div>
								{getWebsiteNameCapitalized()}
							</div>
							{authorized || isDemo ? (
								<button
									className='flex h-[4rem] items-center justify-center rounded-full bg-gradient-to-br from-[#ff8c00] via-[#ff7801] to-[#ff3915] px-2 py-5 text-[24px] font-medium transition-all active:scale-[97%]'
									onClick={() => {
										navigate('/edit')
									}}
								>
									{t('launch')}
								</button>
							) : (
								<>
									<div className='flex w-full flex-col items-center gap-[16px]'>
										<GoogleLoginButton />
										{/* <DiscordLoginButton /> */}
										<EmailLoginButton />
									</div>
								</>
							)}
						</div>
						<div className='flex flex-col gap-[16px] mmd:flex'>
							<div className='relative flex w-[420px] flex-col gap-[16px] overflow-hidden text-[20px] mmd:text-[22px]'>
								<div className='text-gray-500 scrollbar-none max-h-[400px] overflow-y-scroll pr-[16px]'>
									<h1 className='mb-2 text-[28px] font-bold lg:text-[20px]'>
										Undress AI App: try Free AI Undress to make Nudes
									</h1>
									<p className='mb-2 text-[16px] leading-[28px] lg:text-[12px]'>
										The Undress AI Tool is a revolutionary generator designed to
										create realistic deepnude images with ease. This Best
										Undress App lets you transform anyone on a photo into a
										deepfake result within seconds, offering seamless and
										accurate outputs.
									</p>
									<h2 className='mb-2 text-[28px] font-bold lg:text-[20px]'>
										Undress AI Generator Result Examples
									</h2>
									<p className='mb-2 text-[16px] leading-[28px] lg:text-[12px]'>
										The Undress App Results highlight the precision and realism
										of this innovative nudify tool. Browse the gallery to view
										stunning nudes examples, demonstrating why the Undress Nude
										App is trusted for creating accurate and impressive deepnude
										transformations quickly and effortlessly.
									</p>
									<h2 className='mb-2 text-[28px] font-bold lg:text-[20px]'>
										Get Started with the Best AI Undress Tool Now!
									</h2>
									<p className='mb-2 text-[16px] leading-[28px] lg:text-[12px]'>
										The Undress Love AI tool combines simplicity and efficiency,
										making it the perfect choice for realistic transformations.
										Whether you're seeking accuracy or speed, this Free Undress
										CC App ensures impressive nudity results in seconds.
									</p>
									<p className='text-[16px] leading-[28px] lg:text-[12px]'>
										Join countless users who trust this innovative AI solution
										for quality nudes making, proving why it stands out as the
										ultimate choice in AI-powered undressing.
									</p>
								</div>
							</div>

							{/* <a
								target='_blank'
								href='https://t.me/undresslove'
								className='hidden rounded-[20px] bg-[#161616] p-[16px] px-[20px] transition-all hover:bg-[#535353] mmd:flex'
							>
								<div className='flex gap-[20px]'>
									<img
										src='/icons/telegramLogo.svg'
										alt='telegram logo'
										className='h-[48px] w-[48px]'
									/>
									<div className='flex flex-col gap-1 text-sm text-white'>
										Telegram
										<span className='text-[22px]'>@UndressLove</span>
									</div>
								</div>
							</a> */}
						</div>
					</div>
				</div>
				{/* <a
					target='_blank'
					href='https://t.me/undresslove'
					className='flex rounded-[20px] bg-[#161616] p-[20px] transition-all hover:bg-[#535353] mmd:hidden'
				>
					<div className='flex gap-[20px]'>
						<img
							src='/icons/telegramLogo.svg'
							alt='telegram logo'
							className='h-[48px] w-[48px]'
						/>
						<div className='flex flex-col gap-1 text-sm text-white'>
							Telegram
							<span className='text-[22px]'>@UndressLove</span>
						</div>
					</div>
				</a> */}
				<Faq />
				<div>
					<h3 className='mb-[12px] text-left text-[20px] mmd:mb-[0] mmd:text-center'>
						{getWebsiteNameCapitalized()}
					</h3>
					<Footer />
				</div>
			</div>
		</HelmetProvider>
	)
}
