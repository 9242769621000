import { useEffect, useState } from 'react'

export default function FaqItem({ faq, index }) {
	const [accordionOpen, setAccordionOpen] = useState(false)

	useEffect(() => {
		setAccordionOpen(false)
	}, [])

	return (
		<button
			onClick={(e) => {
				e.preventDefault()
				setAccordionOpen(!accordionOpen)
			}}
			aria-expanded={accordionOpen}
			aria-controls={`accordion-text-${index}`}
			className='flex w-full flex-col gap-[24px] lg:max-w-[800px]'
		>
			<div className='flex w-full justify-between gap-[32px] pt-[24px] text-left text-[24px] font-bold lg:gap-[128px]'>
				<h3 className='max-w-[300px] lg:max-w-full'>{faq.question}</h3>
				<svg
					className='ml-8 shrink-0 fill-white'
					width='16'
					height='16'
					xmlns='http://www.w3.org/2000/svg'
				>
					<rect
						y='7'
						width='16'
						height='2'
						rx='1'
						className={`origin-center transform transition duration-200 ease-out ${
							accordionOpen && '!rotate-180'
						}`}
					/>
					<rect
						y='7'
						width='16'
						height='2'
						rx='1'
						className={`origin-center rotate-90 transform transition duration-200 ease-out ${
							accordionOpen && '!rotate-180'
						}`}
					/>
				</svg>
			</div>
			<div
				id={`accordion-text-${index}`}
				role='region'
				aria-labelledby={`accordion-title-${index}`}
				className={`grid overflow-hidden transition-all duration-300 ease-in-out ${
					accordionOpen
						? 'grid-rows-[1fr] opacity-100'
						: 'grid-rows-[0fr] opacity-0'
				}`}
			>
				<div className='overflow-hidden'>
					<p className='pb-3 text-left text-[16px] text-white/60'>
						{faq.answer}
					</p>
				</div>
			</div>
			<span className='h-[1px] w-full bg-white/10' />
		</button>
	)
}
