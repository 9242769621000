const faqData = [
	{
		question: 'What is Undress Love AI?',
		answer: `Undress love is a free AI tool designed to undress photos in just a few seconds. With a variety of modes such as Lingerie, Blowjob, Undress, X-ray, Sex poses, Bikini, Cumshot, Shibari, and BDSM, the platform caters to a wide range of creative needs. Undress love supports both male and female photos and offers multiple quality options, including Standard, HD, and 4K.`,
	},
	{
		question: 'Is AI Undress Love safe?',
		answer: `Yes, Undress love prioritizes user safety and data privacy. The platform does not store or share any uploaded images, ensuring complete control over your content. We use encrypted connections to protect your data and maintain a secure user environment.`,
	},
	{
		question: 'Is AI Undress Love legal?',
		answer: `Yes, Undress Love, just like Undress CC, is completely legal to use, provided that all content is handled responsibly and in compliance with local laws and regulations. Both platforms strictly prohibit any illegal, unethical, or non-consensual use of their tools.`,
	},
	{
		question: 'How to use the Undress Love App?',
		answer: `Using Undress love app is simple and hassle-free. There’s no need to download or install anything. Just log in with your Google account, choose an AI undressing mode, upload a photo, and get results instantly.`,
	},
	{
		question: 'Does Undress AI Love save photos?',
		answer: `No, Undress love AI app does not save any photos or images on its servers. The platform is designed with user privacy in mind and ensures that all uploaded images remain secure and private, accessible only to the user.`,
	},
]

export default faqData
