
import faqData from '~/lib/data/faqData'
import FaqItem from './faqItem'

export default function Faq() {
	return (
		<section
			id='faq'
			className='max-w-screen flex w-full justify-center overflow-hidden pb-[64px] pt-[20px]'
		>
			<div className='flex w-full justify-between px-[20px] lg:max-w-[1150px]'>
				<div className='flex w-full flex-col items-center justify-between lg:flex-row'>
					<div className='items-left flex w-full flex-col justify-between lg:flex-row lg:items-start'>
						<h2 className='pb-[24px] pt-[16px] text-start text-[30px] font-bold lg:text-[36px]'>
							FAQ
						</h2>
						<span className='h-[1px] w-full bg-white/10 lg:hidden' />
						<div className='flex flex-col items-center gap-[8px] lg:items-start'>
							{faqData.map((faq, index) => (
								<FaqItem faq={faq} key={index} index={index} />
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
