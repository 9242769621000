import { getWebsiteNameCapitalized } from '~/lib/utils'
import { Footer } from '~/shared/ui/footer'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

export default function Rules() {
	const navigate = useNavigate()

	return (
		<HelmetProvider>
			<div className='flex min-h-screen flex-col bg-black'>
				<Helmet>
					<title>Rules</title>
					<link rel="canonical" href="https://undress.love/rules" />
				</Helmet>
				<div className='flex flex-grow flex-col items-center gap-[20px]'>
					<div className='flex w-full items-center justify-center gap-[40px] p-4 pt-[50px] llg:pt-[100px]'>
						<div className='flex w-full max-w-[1110px] flex-col items-center justify-between gap-[40px]'>
							<div className='flex w-full items-center justify-between'>
								<Link to='/'>
									<h4 className='text-[28px] font-medium'>
										{getWebsiteNameCapitalized()}
									</h4>
								</Link>
								<Link to='/'>
									<img src='logo.svg' className='h-auto w-[45px]' alt='logo' />
								</Link>
							</div>
							<div className='flex flex-col gap-8 text-center'>
								<h5 className='text-[24px] font-semibold'>Rules</h5>
								<ul className='flex flex-col items-center gap-5 pt-5 text-[22px] font-medium'>
									<li>
										1. By entering this website you confirm to be 18+ to use
										this website. If you are under 18 please immediately leave
										this website.
									</li>
									<li>
										2. You can’t use photos of other people without their prior
										written permission.
									</li>
									<li>
										3. You are solely responsible for the images you generate.
									</li>
									<li>
										4. This website is created only for entertainment purposes
										and is not anyhow aimed to insult anyone.
									</li>
									<li className='max-w-[350px] pt-10'>
										By clicking on <span className='text-orange'>Accept</span>{' '}
										you automatically agree to the above terms and the other
										provisions of the website
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className='flex justify-center p-[30px]'>
					<button
						onClick={() => {
							Cookies.set('birth', 'true')
							localStorage.setItem('restore', true)
							navigate('/edit?restoreOld=true')
						}}
						className='w-full max-w-[350px] rounded-full bg-orange px-16 py-4 text-[22px] font-medium transition-all'
					>
						Accept
					</button>
				</div>
				<div className='p-[30px]'>
					<Footer />
				</div>
			</div>
		</HelmetProvider>
	)
}
