import terms from '~/lib/data/termsData'
import { getWebsiteNameCapitalized } from '~/lib/utils'
import { Footer } from '~/shared/ui/footer'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link } from 'react-router-dom'

export default function TermsOfService() {
	console.log(document.querySelector('link[rel="canonical"]'));

	return (
		<HelmetProvider>
			<div className='flex min-h-screen flex-col bg-black'>
				<Helmet>
					<title>Terms Of Service</title>
					<link rel="canonical" href={`https://undress.love${location.pathname}`} />
				</Helmet>
				<div className='flex flex-grow flex-col items-center gap-[20px]'>
					<div className='flex w-full items-center justify-center gap-[40px] p-4 pt-[50px] llg:pt-[100px]'>
						<div className='flex w-full max-w-[1110px] flex-col items-center justify-between gap-[40px]'>
							<div className='flex w-full items-center justify-between'>
								<Link to='/edit'>
									<h4 className='text-[28px] font-medium'>
										{getWebsiteNameCapitalized()}
									</h4>
								</Link>
								<Link to='/edit'>
									<img src='logo.svg' className='h-auto w-[45px]' alt='logo' />
								</Link>
							</div>
							<div className='flex w-full flex-col justify-start gap-8'>
								<h5 className='text-[28px] font-semibold'>Terms Of Service</h5>
								<div className='flex flex-col gap-4 text-lg'>
									<p>
										These Terms of Service govern your use of the website
										located at https://undress.love and any related services
										provided by Undress.love.
									</p>
									<p>
										By accessing https://undress.love, you agree to abide by
										these Terms of Service and to comply with all applicable
										laws and regulations. If you do not agree with these Terms
										of Service, you are prohibited from using or accessing this
										website or using any other services provided by Undress.app.
									</p>
									<p>
										We, Undress.app, reserve the right to review and amend any
										of these Terms of Service at our sole discretion. Upon doing
										so, we will update this page. Any changes to these Terms of
										Service will take effect immediately from the date of
										publication.
									</p>
									<p>
										These Terms of Service were last updated on 19 April 2024.
									</p>
								</div>
								{terms.map((term, index) => (
									<div key={index}>
										<div className='flex flex-col gap-4'>
											<h6 className='text-[24px] font-semibold'>
												{term.title}
											</h6>
											<p className='text-lg'>{term.text}</p>
											{term.subparagraphs?.map((subparagraph, index) => (
												<p className='text-lg' key={index}>
													{subparagraph}
												</p>
											))}
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className='flex justify-center p-[30px]'>
					<Link
						to='/edit'
						onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
						className='p-4 text-[22px] font-medium text-white/50 transition-all hover:text-white/30'
					>
						Back to edit
					</Link>
				</div>
				<div className='p-[30px]'>
					<Footer />
				</div>
			</div>
		</HelmetProvider>
	)
}
