import privacy from '~/lib/data/privacyData'
import { getWebsiteNameCapitalized } from '~/lib/utils'
import { Footer } from '~/shared/ui/footer'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link } from 'react-router-dom'

export default function PrivacyPolicy() {
	return (
		<HelmetProvider>
			<div className='flex min-h-screen flex-col bg-black'>
				<Helmet>
					<title>Privacy Policy</title>
					<link rel="canonical" href="https://undress.love/privacy" />
				</Helmet>
				<div className='flex flex-grow flex-col items-center gap-[20px]'>
					<div className='flex w-full items-center justify-center gap-[40px] p-4 pt-[50px] llg:pt-[100px]'>
						<div className='flex w-full max-w-[1110px] flex-col items-center justify-between gap-[40px]'>
							<div className='flex w-full items-center justify-between'>
								<Link to='/edit'>
									<h4 className='text-[28px] font-medium'>
										{getWebsiteNameCapitalized()}
									</h4>
								</Link>
								<Link to='/edit'>
									<img src='logo.svg' className='h-auto w-[45px]' alt='logo' />
								</Link>
							</div>
							<div className='flex w-full flex-col justify-start gap-8'>
								<h5 className='text-[28px] font-semibold'>Privacy Policy</h5>
								{privacy.map((privacy, index) => (
									<div key={index}>
										<div className='flex flex-col gap-4'>
											<h6 className='text-[24px] font-semibold'>
												{privacy.title}
											</h6>
											<p className='text-lg'>{privacy.text}</p>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className='flex justify-center p-[30px]'>
					<Link
						to='/edit'
						onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
						className='p-4 text-[22px] font-medium text-white/50 transition-all hover:text-white/30'
					>
						Back to edit
					</Link>
				</div>
				<div className='p-[30px]'>
					<Footer />
				</div>
			</div>
		</HelmetProvider>
	)
}
